import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseData } from './response';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class FvNoteService {
    response = [];
    constructor(
        private http: HttpClient
        ) {
    }

    getData(url): Promise<ResponseData[]> {
        return this.http.get(url).toPromise().then((data : any) => this.extractData(data)).catch(err => {
            return Promise.reject(err.error || 'Server error');
        });
    }

    postData(url, formData) {
        return this.http.post(url, formData).toPromise().then((data : any) => this.extractData(data)).catch(err => {
            return Promise.reject(err.error || 'Server error');
        });
    } 

    extractData(data) {
        return Promise.resolve(data);
    }
}